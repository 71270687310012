.row {
    margin: 5px auto;
}

.row-header {
    margin: 0px auto;
    padding: 0px auto;
}

.row-content {
    margin: 0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height: 400px;
}

.jumbotron {
    padding: 70px 30px 70px 30px;
    margin: 0px auto;
    background: #9575CD;
    color: floralwhite;
}

address {
    font-size: 80%;
    margin: 0px;
    color: #0f0f0f;
}

.navbar-dark {
    background-color: #512DA8;
}

.select {
    display: grid;
}

.react-datepicker-wrapper {
    width: 100%;
}

.datePicker>div>div.react-datepicker__input-container input {
    width: 100%;
}

.table {
    margin-left: 10px;
}

.table-whoisjoining {
    margin-left: 0px;
}

.sidebar {
    display: flex;
    height: 100vh;
}